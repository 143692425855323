import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';


@Component({
    selector: 'app-page-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnChanges {

    @Input() body;
    @Input() public;
    public parsedBody = [];

    public phoenix_store;

    constructor(private router: Router,
                private elRef: ElementRef) {
    }

    ngOnInit(): void {
        this.parseBody(this.body);
        
        document.addEventListener('input', (event) => {
            let target = event.target as HTMLInputElement;
            const hasClass = target.classList.contains('crossword-board__item');

            if(hasClass){
                const inputs = Array.from(document.querySelectorAll('.crossword-board__item'));
        
                let currentIndex = inputs.indexOf(target);
        
                if (target.value.length === target.maxLength) {
                    let nextInput = inputs[currentIndex + 1] as HTMLInputElement;
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
            }
        });
        document.addEventListener('keydown', (event)=>{
            let target = event.target as HTMLInputElement;
            const hasClass = target.classList.contains('crossword-board__item');

            if(hasClass) {
                const inputs = Array.from(document.querySelectorAll('.crossword-board__item'));  
                let currentIndex = inputs.indexOf(target);

                if(event.key==='Backspace' && target.value===''){
                    let previousInput = inputs[currentIndex-1] as HTMLInputElement;
                    if(previousInput){
                        previousInput.focus();
                    }
                }    

            }
        });
    }

    private parseBody(body: string) {
        this.parsedBody = [];
        if (body !== undefined) {
            const matches = body.match(/\[form=([0-9]*)\]/g);

            const phoenix_match = body.match(/\[phoenix_store=(.+)*\]/g)
            let bod = body;
            let split = [];


            if (matches) {
                for (const match of matches) {

                    split = bod.split(match);
                    console.log(split);

                    this.parsedBody.push({data: split[0], type: 'string'});

                    this.parsedBody.push({data: match.replace(/\D/g,'').trim(), type: 'form'});
                    if (split.length > 1) {
                        this.parsedBody.push({data: split[1], type: 'string'});
                    }
                }
            } else {
                if (phoenix_match) {
                    for (const store of phoenix_match) {
                        const split = bod.split(store);
                        this.parsedBody.push({data: split[0], type: 'string'});
                        if (split.length > 1) {
                            this.parsedBody.push({data: store.replace('[phoenix_store=', '').replace(']','').trim(), type: 'phoenix_store'});
                            this.parsedBody.push({data: split[1], type: 'string'});

                        }
                    }
                } else {
                    this.parsedBody.push({data: body, type: 'string'});
                }
            }
        }
    }

    scrollTo($event: MouseEvent) {
        const target = $event.target;

        if (target['classList'].contains('scrollTo')) {
            $event.stopPropagation();
            $event.preventDefault();

            const hash = target['hash'].replace('#', '').trim();

            this.elRef.nativeElement.getElementsByClassName(hash)[0].scrollIntoView({behavior: 'smooth'});
        }
    }
    

    ngOnChanges(changes: SimpleChanges) {
        this.parseBody(changes['body'].currentValue);
    }

}